import React from 'react';
import Carousel from 'react-multi-carousel';
import AwardsAndRecognitionCard from './AwardsAndRecognitionCard';
import 'react-multi-carousel/lib/styles.css';
import * as classes from './AwardsAndRecognitionCarousel.module.css';

export const AWARDS = [
  {
    title: 'Top 1000 Companies on Clutch ',
    year: '(2020)',
    imgSrc: 'clutch1.png',
    link: 'https://clutch.co/profile/tintash',
  },
  {
    title: 'Clients Say We Deliver on Clutch',
    year: '',
    imgSrc: 'clutch.png',
    link: 'https://clutch.co/profile/tintash',
  },
  {
    title: 'AWS Select Consulting Partner ',
    year: '2020',
    imgSrc: 'aws-partner.png',
    link: '',
  },
  {
    title: 'Top 1000 Companies on Clutch  ',
    year: '(2020)',
    imgSrc: 'clutch.png',
    link: 'https://clutch.co/profile/tintash',
  },
  {
    title: 'Top Mobile App Development Company ',
    year: '',
    imgSrc: 'top-mobile-app.png',
    link: 'https://themanifest.com/app-development/companies',
  },
];

export const CustomDot = ({index, onClick, active}) => (
  <div
    role="button"
    onClick={e => {
      onClick();
      e.preventDefault();
    }}
    className={`${classes.dot} ${active ? classes.dotActive : ''}`}
  />
);

function AwardsAndRecognitionCarousel(props) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: AWARDS.length,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 3 // optional, default to 1.
    }
  };

  return (
    <div style={{position: 'relative'}} className="py-5">
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={6000}
        customTransition="transform 800ms ease-out"
        transitionDuration={800}
        centerMode={false}
        containerClass="container"
        className="connect__carousel-list"
        itemClass="connect__carousel-item"
        draggable={true}
        focusOnSelect={false}
        infinite={true}
        keyBoardControl={false}
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={true}
        responsive={responsive}
        showDots={false}
        swipeable={true}
        ssr={true}
      >
        {AWARDS.map((award, i) => (
          <AwardsAndRecognitionCard key={i} {...award} />
        ))}
      </Carousel>
    </div>
  );
}

export default AwardsAndRecognitionCarousel;
