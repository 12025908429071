import React from 'react';
import * as classes from './AwardsAndRecognitionCard.module.css';

function AwardsAndRecognitionCard({imgSrc, title, year, link}) {

  return (
    <div>
      {link != "" ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={`${classes.card}`}
        >
          <div className={classes.imgContainer}>
            <img src={require(`../../../assets/images/connect/awards/${imgSrc}`).default}
                alt={title} />
          </div>
          <p className={`${classes.title}`}>{title}</p>
          <p className={`${classes.year}`}>{year}</p>
        </a>
      ) : (
        <div>
          <div className={`${classes.card}`}>
            <div className={classes.imgContainer}>
              <img src={require(`../../../assets/images/connect/awards/${imgSrc}`).default}
                  alt={title} />
            </div>
            <p className={`${classes.title}`}>{title}</p>
            <p className={`${classes.year}`}>{year}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default AwardsAndRecognitionCard;
